import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import PopoverComponent from 'src/views/components/PopoverComponent'
import FormikTinyMce from 'src/views/components/forms/FormikTinyMce'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface CompetencyCriteria {
  criteriaId: number
  text: string
}

interface Competency {
  competencyId: number
  criterias: CompetencyCriteria[]
}

interface FormikValues {
  readonly mentorId: string
  readonly text: string
  readonly id: string
  readonly files: readonly FileState[]
  readonly competencies: Competency[]
}

export default function StudentPortfolioWorkAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:portfolio_add_work')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const params = useParams()

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const section = useApi({
    endpoint: Api.getStudentPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.id!,
        },
      }),
      [headers, params.id]
    ),
  })

  const comps = useApi({
    endpoint: Api.getStudentPortfolioCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.id!,
        },
      }),
      [headers, params.id]
    ),
  })

  const initialValues: FormikValues = React.useMemo(() => {
    if (params.id === '2') {
      return {
        id: params.id!,
        mentorId: '',
        text: '',
        files: [],
        competencies:
          comps.data.data?.map((c) => ({
            competencyId: Number(c.id),
            criterias:
              c.criterias?.map((criteria) => ({
                criteriaId: Number(criteria.id),
                text: '',
              })) ?? [],
          })) ?? [],
      }
    } else if (params.id === '3') {
      return {
        id: params.id!,
        mentorId: '',
        text: '',
        files: [],
        competencies:
          comps.data.data?.map((c) => ({
            competencyId: Number(c.id),
            criterias: [],
          })) ?? [],
      }
    } else {
      return {
        id: params.id!,
        mentorId: '',
        text: '',
        files: [],
        competencies: [],
      }
    }
  }, [comps.data.data, params.id])

  const onSubmit = React.useCallback(
    async (
      { mentorId, files, text, competencies }: FormikValues,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<void> => {
      // debugger
      const filteredCompetencies =
        params.id === '2'
          ? competencies
          : competencies.map(({ competencyId }) => ({
              competencyId,
            }))

      await Api.putStudentPortfolioWork({
        headers,
        body: {
          mentorId,
          ...(text && { text }),
          competencies: filteredCompetencies,
          files: files.flatMap((e) => e.remoteFile?.id ?? []),
        },
        args: {
          id: params.id!,
          workId: '',
        },
      })
      toast.success(t('portfolio:work_submitted'))
      formikHelpers.resetForm()
      setSuccessfullySubmited(true)
    },
    [headers, t, params.id]
  )
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/student/portfolio/sections/${params.id}/works`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/student/portfolio` },
    { page: `${section.data.name}`, path: pathname },
    { page: `${t('portfolio:portfolio_add_work')}`, path: pathname },
  ]
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isConfirmable={!successfullySubmited}>
      <Section
        title={t('portfolio:portfolio_add_work')}
        icon={<Portfolio />}
        breadcrubms={breadcrumbsItems}
        rightElement={<BackButton link={`/${locale}/student/portfolio/sections/${params.id}/works`} />}
      >
        <SuspenseWrapper>
          <FormContent />
        </SuspenseWrapper>
      </Section>
    </Form>
  )
}

function FormContent(): React.ReactElement | null {
  const { id } = useParams()
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const [fileInputId] = React.useState(() => `${id}-${Date.now()}`)
  const params = useParams()

  const mentors = useApi({
    endpoint: Api.getStudentPortfolioMentros,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const sectionCompetencies = useApi({
    endpoint: Api.getStudentPortfolioCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  return (
    <div className="w-full rounded-card bg-card px-6 py-9">
      <FormError />
      <div className="mb-4 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
        {t('portfolio:competence')}
      </div>
      {(() => {
        if (sectionCompetencies.data.data != null) {
          if (id == '2') {
            return sectionCompetencies.data.data.map((competencyItem, index) => (
              <div key={competencyItem.id} className="mb-6 rounded-sm border border-borderGrey p-4">
                <div className="mb-3 mr-4 flex items-center">
                  <label className="text-primaryTextColor" htmlFor={competencyItem.id}>
                    <span className="font-bold">
                      {competencyItem.name}
                      <PopoverComponent>
                        <span
                          className="mb-3 flex py-2 leading-6"
                          dangerouslySetInnerHTML={{ __html: competencyItem.description }}
                        />
                        {competencyItem.criterias?.map((criteria) => (
                          <div key={criteria.id} className="my-2">
                            <label className="mb-3 text-primaryTextColor">
                              <div className="my-3">
                                {criteria.name}
                                <div>
                                  <span className="p-2" dangerouslySetInnerHTML={{ __html: criteria.description }} />
                                </div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </PopoverComponent>
                    </span>
                  </label>
                </div>
                {/* {id == '2' && formik.values.competencies.includes(c.id) && ( */}
                <div className="flex">
                  {competencyItem.criterias?.map((criteria, criteriaIndex) => (
                    <div key={criteria.id} className="mb-3 mr-2 flex w-full">
                      <TextareaInput
                        label={criteria.name}
                        name={`competencies[${index}]criterias[${criteriaIndex}]text`}
                        rows={5}
                        required
                      />
                    </div>
                  ))}
                </div>
                {/* )} */}
              </div>
            ))
          } else {
            return (
              <div className="mb-6">
                {sectionCompetencies.data.data.map((c) => (
                  <div key={c.id}>
                    <div className="mb-3 mr-4 flex items-center">
                      <input
                        name="competencies"
                        type="checkbox"
                        id={c.id}
                        className="border border-tableBorderColor"
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            formik.setFieldValue('competencies', [
                              ...formik.values.competencies,
                              {
                                competencyId: c.id,
                              },
                            ])
                          } else if (id !== '3') {
                            formik.setFieldValue(
                              'competencies',
                              formik.values.competencies.filter((v) => v.competencyId.toString() !== c.id)
                            )
                          }
                        }}
                        checked={
                          formik.values.competencies.some((v) => v.competencyId.toString() === c.id) || id === '3'
                        }
                        disabled={id === '3'}
                      />

                      <label className="text-primaryTextColor" htmlFor={c.id}>
                        <span className="ml-2">
                          {c.name}
                          <PopoverComponent>
                            <span
                              className="mb-3 flex py-2 leading-6"
                              dangerouslySetInnerHTML={{ __html: c.description }}
                            />
                            {c.criterias?.map((criteria) => (
                              <div key={criteria.id} className="my-2">
                                <label className="mb-3 text-primaryTextColor">
                                  <div className="my-3">
                                    {criteria.name}
                                    <div>
                                      <span
                                        className="p-2"
                                        dangerouslySetInnerHTML={{ __html: criteria.description }}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </PopoverComponent>
                        </span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        }
      })()}

      <div className="mb-6">
        <SelectInput
          placeholder={t('common:choose')}
          label={t('portfolio:mentor')}
          name="mentorId"
          options={
            mentors.data
              .filter((mentor) => mentor.manage?.canChoose)
              .map((mentor) => ({
                label: `${mentor.firstName} ${mentor.lastName}`,
                value: mentor.id,
              })) ?? []
          }
        />
      </div>
      {id == '2' ? null : (
        <div>
          <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
            {t('portfolio:add_work_description')}
          </div>
          <FormikTinyMce name="text" />
        </div>
      )}

      <div className="my-6" style={{ zIndex: 0, maxWidth: 1065 }}>
        <FileInput
          key={fileInputId}
          multiple
          config={Api.UploadConfigMap.studentPortfolio(params.id!)}
          onChange={(files) => void formik.setFieldValue('files', files)}
        />
      </div>
      <div className="flex w-full justify-end">
        <Button type="submit" variant="red" disabled={!formik.isValid || !formik.dirty}>
          {t('common:save_as_draft')}
        </Button>
      </div>
    </div>
  )
}
