import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { Draft } from 'src/assets/icons/customIcons/Draft'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import CoursePending from 'src/assets/icons/customIcons/course-icons/CoursePending'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentPortfolioSectionHistoryPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:portfolio')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { id } = useParams()
  const locale = useLocale()
  const theme = useTheme()
  const [searchParams] = useSearchParams({
    page: '1',
  })
  const section = useApi({
    endpoint: Api.getStudentPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })
  const page = searchParams.get('page')!

  const { data: worksHistory, isValidating: worksPending } = useApi({
    endpoint: Api.getStudentPortfolioSectionHistoryWorks,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
        query: {
          page: parseInt(page),
        },
      }),
      [headers, id, page]
    ),
    suspense: false,
  })

  return (
    <Section>
      {worksHistory != null && worksHistory.data.length > 0 ? (
        <>
          <PaginationTop pagination={worksHistory!.meta.pagination} />
          <div className="w-full py-3">
            {worksPending ? (
              <Loader className="flex" style={{ margin: 'auto' }} />
            ) : (
              <Table.Table className="mt-4">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th>{t('portfolio:work_description')}</Table.Th>
                    <Table.Th>{t('portfolio:competence')}</Table.Th>
                    <Table.Th>{t('portfolio:work_mentor')}</Table.Th>
                    <Table.Th>{t('common:semester')}</Table.Th>
                    <Table.Th>{t('common:date')}</Table.Th>
                    <Table.Th>{t('common:status')}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {worksHistory.data.map((work) => (
                    <Table.Tr key={work.id}>
                      <Table.Td className="px-5 text-center align-middle">
                        <div>
                          {work.status === 'STATUS_PENDING' && (
                            <div data-tooltip-id={`${work.id}-statusTooltip`}>
                              <CoursePending />
                            </div>
                          )}
                          {work.status === 'STATUS_EVALUATED' ? (
                            <div data-tooltip-id={`${work.id}-statusTooltip`}>
                              <CourseCompletedIcon />{' '}
                            </div>
                          ) : null}
                          {work.status === 'STATUS_REJECTED' && (
                            <div data-tooltip-id={`${work.id}-statusTooltip`}>
                              <CourseFailedIcon />
                            </div>
                          )}
                          {work.status === 'STATUS_DRAFT' && (
                            <div data-tooltip-id={`${work.id}-statusTooltip`}>
                              <Draft />
                            </div>
                          )}
                          <Tooltip
                            id={`${work.id}-statusTooltip`}
                            place="top"
                            variant={theme === 'dark' ? 'dark' : 'light'}
                            className="mb-1 p-1"
                          >
                            <p className="mb-0">
                              {work.status === 'STATUS_PENDING' && t('portfolio:status_pending')}
                              {work.status === 'STATUS_EVALUATED' && t('portfolio:status_evaluated')}
                              {work.status === 'STATUS_REJECTED' && t('portfolio:status_rejected')}
                              {work.status === 'STATUS_DRAFT' && t('portfolio:status_draft')}
                            </p>
                          </Tooltip>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        <Link to={`/${locale}/student/portfolio/sections/${section.data.id}/works/${work.id}`}>
                          {id == '2' || work.text ? (
                            <span>{work.id}</span>
                          ) : work.text ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: work.text.length > 40 ? work.text.substring(0, 60) + '...' : work.text,
                              }}
                            />
                          ) : null}
                        </Link>
                      </Table.Td>
                      <Table.Td>
                        {work.competencies?.slice(0, 2).map((competency, index, array) => (
                          <span key={competency.id}>
                            {(index !== 0 ? ', ' : '') + competency.name}
                            {index === array.length - 1 ? '...' : ''}
                          </span>
                        ))}
                      </Table.Td>
                      <Table.Td className="whitespace-nowrap pr-4 align-middle">
                        <UserPhoto className="scale-[0.7]" user={work.mentor} />{' '}
                        <Link
                          to={`/${locale}/users/${work.mentor?.uid}`}
                          className="ml-2 whitespace-nowrap align-middle hover:underline"
                          data-tooltip-id={`${work.id}-lecturer`}
                        >
                          <span className="ml-2">{work.mentor?.fullName}</span>
                        </Link>
                      </Table.Td>
                      <Table.Td className="px-5 text-center align-middle">{work.semester}</Table.Td>

                      <Table.Td className="px-5 text-center align-middle">{work.createdAt}</Table.Td>
                      <Table.Td className="px-5 text-center align-middle">
                        <div>
                          {work.status === 'STATUS_PENDING' && <div>{t('portfolio:status_pending')}</div>}
                          {work.status === 'STATUS_EVALUATED' ? <div>{t('portfolio:status_evaluated')}</div> : null}
                          {work.status === 'STATUS_REJECTED' && <div>{t('portfolio:status_rejected')}</div>}
                          {work.status === 'STATUS_DRAFT' && <div>{t('portfolio:status_draft')}</div>}
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table.Table>
            )}
          </div>

          <PaginationBottom pagination={worksHistory!.meta.pagination} />
        </>
      ) : worksPending ? (
        <Loader className="flex" style={{ margin: 'auto' }} />
      ) : (
        <NoContent header={t('error:records_not_found')} image={Warning} />
      )}
    </Section>
  )
}
