import * as React from 'react'
import { useParams } from 'react-router-dom'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function StudentPortfolioLayout(): JSX.Element | null {
  return (
    <React.Suspense fallback={<Loader className="m-auto flex" />}>
      <ErrorBoundary errorElement={<ErrorElement />}>
        <PageContent />
      </ErrorBoundary>
    </React.Suspense>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const { id } = useParams()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()

  const { data } = useApi({
    endpoint: Api.getStudentPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
    shouldFetch: id != null,
  })

  const subBreadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/student/portfolio` },
    { page: `${data?.name}`, path: `/${locale}/student/portfolio/sections/${id}/works/listing` },
  ]

  return (
    <SubPageLayout
      Icon={Portfolio}
      title={t('portfolio:portfolio')}
      subBreadcrubms={subBreadcrumbsItems}
      pages={[
        {
          title: t('portfolio:works'),
          link: 'listing',
        },
        {
          title: t('portfolio:portfolio_scores'),
          link: 'scores',
        },
        {
          title: t('portfolio:portfolio_history'),
          link: 'history',
        },
      ]}
    />
  )
}
