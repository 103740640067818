import { t } from 'i18next'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import { SurveyIcon } from 'src/assets/icons/customIcons/header-icons/Survey'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function StudentPortfolioSectionsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:general')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const { pathname } = useLocation()
  const fetching = useApi({
    endpoint: Api.getStudentPortfolioSections,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const getProgress = (score: number | 0, maxScore: number) => {
    // return (score / maxScore) * 100
    return (score / maxScore) * 100
  }
  const sections = fetching.data.data

  const breadcrumbsItems = [{ page: `${t('portfolio:dashboard')}`, path: pathname }]

  return (
    <Section title={`${t('portfolio:portfolio_dashboard')}`} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
      <>
        {sections.map((section) => (
          <div key={section.id} className="mt-6 text-primaryTextColor">
            <div className="mb-3 ml-3 flex items-center justify-between">
              <div className="flex items-center">
                <div className="text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]">
                  {section.name}
                </div>
                <div className="ml-3">
                  <Link to={`/${locale}/student/portfolio/sections/${section.id}/works`}>
                    <Button variant="minimal" className="flex !h-[24px] items-center !px-1">
                      <span className="!text-xs !text-captionColor"> {t('portfolio:show_details')}</span>
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="flex items-center">
                <div className="ml-3 flex items-center">
                  <SurveyIcon />
                </div>
                <div className="ml-2 flex items-center !text-xs !text-captionColor">
                  {' '}
                  {`${section.totalProgress?.score} ${t('person:points')}`}
                </div>
              </div>
            </div>

            <Table.Table className="min-w-[768px]">
              <Table.Thead>
                <Table.Tr className="w-full">
                  {section.id !== '2' ? (
                    section.competencies?.map((competency) => (
                      <Table.Th key={competency.id} className="!text-center">
                        <div> {`${competency.name}`}</div>
                        <div className="text-xs !font-light">
                          {''}
                          {`(${t('portfolio:portfolio_min')} ${competency.minScore} - ${t('portfolio:portfolio_max')} ${competency.maxScore})`}
                        </div>
                      </Table.Th>
                    ))
                  ) : (
                    <Table.Th className="!text-center">
                      {t('portfolio:total_rate')}
                      <div className="text-xs !font-light">
                        {''}
                        {`(${t('portfolio:portfolio_min')} ${section.minScore} - ${t('portfolio:portfolio_max')} ${section.maxScore})`}
                      </div>
                    </Table.Th>
                  )}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  {section.id !== '2' ? (
                    section.competencies?.map((competency) => {
                      const score = competency.progress?.score ?? 0
                      return (
                        <Table.Td key={competency.id} className="text-center">
                          {competency.minScore != null && score <= competency.minScore ? (
                            <div className="mt-2 text-primaryRed">{score}</div>
                          ) : competency.minScore != null && score > competency.minScore ? (
                            <div className="mt-2 text-primaryGreen">{score} </div>
                          ) : (
                            score
                          )}
                        </Table.Td>
                      )
                    })
                  ) : (
                    <Table.Td className="!text-center">
                      {' '}
                      <div className="relative mr-2 mt-3 flex justify-center text-title">
                        <div className="relative flex h-[8px] rounded-[3px] bg-veryLightGrey" style={{ width: '40%' }}>
                          <div
                            className="relative rounded-l-[3px] border-r border-r-lightPrimaryIconText"
                            style={{ width: getProgress(section.minScore, section.maxScore) + '%' }}
                          >
                            <div className={`absolute left-full top-full !text-xs !text-captionColor`}>
                              {section.minScore}
                            </div>
                          </div>
                          <div className="absolute left-0 top-0 size-full">
                            {section.totalProgress?.score !== undefined && section.totalProgress !== null ? (
                              <div
                                className="relative h-full rounded-[3px] !bg-primaryGreen"
                                style={{ width: getProgress(section.totalProgress.score, section.maxScore) + '%' }}
                              >
                                <div className={`absolute bottom-full left-full !text-xs`}>
                                  <div className="flex w-max pb-2">
                                    {section.totalProgress.score} {t('person:points')}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="relative h-full">
                                <div className={`absolute top-full !text-xs !text-captionColor`}>
                                  {section.totalProgress?.score}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 !text-xs !text-captionColor">{`${section.maxScore}`}</div>
                      </div>
                    </Table.Td>
                  )}
                </Table.Tr>
              </Table.Tbody>
            </Table.Table>
          </div>
        ))}
      </>
    </Section>
  )
}
