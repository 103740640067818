import { format, parseISO } from 'date-fns'
import * as React from 'react'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'

import { useUserStateMutateOnMount } from 'src/hooks/userState'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function StudentBillingBalancePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <SuspenseWrapper title={t('billing:balance_financial_information')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const dateLocale = useDateLocale()

  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getStudentBillingBalance,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const state = data

  return (
    <div className="w-full rounded-xl border border-solid border-borderGrey bg-card p-6">
      <h1 className="text-2xl font-bold" data-testid="balanceValue">
        ₾ {(state.balance / 100).toFixed(2)}
      </h1>
      {state.updatedAt != null && (
        <p className="pt-1 text-caption text-lightPrimaryIconText" data-testid="balanceInfo">
          {t('billing:balance_last_updated')}:{' '}
          {format(parseISO(state.updatedAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
        </p>
      )}
    </div>
  )
}
